import { useQuery } from "@tanstack/react-query";
import getJobForm from "../services/get-job-form";
import useAccessToken from "./useAccessToken";

/**
 * Custom hook to fetch job form data based on a job key.
 * Uses React Query for data fetching and caching.
 * Automatically includes the access token in the request.
 *
 * @param {string} [jobKey] - Optional job key to fetch specific job form data
 * @returns {Object} Query result object with the following properties:
 * @returns {boolean} .isLoading - Whether the query is in progress
 * @returns {boolean} .isError - Whether the query encountered an error
 * @returns {Object} .error - Error object if the query failed
 * @returns {boolean} .isSuccess - Whether the query was successful
 * @returns {Object} .data - The job form data if the query was successful
 */
const useJobLoader = (jobKey) => {
  const accessTokenPromise = useAccessToken();

  return useQuery({
    queryKey: ["jobLoader"],
    queryFn: async () => {
      const accessToken = await accessTokenPromise;
      return getJobForm(jobKey, accessToken);
    },
  });
};

export default useJobLoader;
