import { useMemo } from "react";
import OverrideResultsButtonRenderer from "../../ag-grid/cellRenderer/overrideButtonRenderer";
import ResultCellRenderer from "../../ag-grid/cellRenderer/ResultCellRenderer";

/**
 * Custom hook that provides column definitions for the Results Page grid.
 *
 * This hook defines columns for displaying checkpoint results and includes
 * functionality for overriding results through a custom button renderer.
 *
 * @param {Function} setOverrideData - Callback function to set the override data when
 *                                    the override button is clicked
 *
 * @returns {Array} An array of column definition objects for AG Grid with the following columns:
 *   - Checkpoint Name: Name of the checkpoint being evaluated
 *   - Result: Status of the checkpoint with custom status renderer
 *   - Override Results: Button column with custom renderer for overriding results
 *
 * @example
 * const [overrideData, setOverrideData] = useState(null);
 * const columnDefs = useResultsPageColumnDefs(setOverrideData);
 * <AgGridReact columnDefs={columnDefs} />
 */
const useResultsPageColumnDefs = (setOverrideData, disabledButtons, fileDescriptions) => {
  return useMemo(
    () => [
      {
        headerName: "Checkpoint Name",
        field: "checkpoint_name",
        headerTooltip: "Checkpoint Name",
        tooltipValueGetter: (params) => params.value,
      },
      {
        headerName: "Result",
        field: "reviewed_result",
        cellRenderer: ResultCellRenderer,
        maxWidth: 150,
        headerTooltip: "Result",
        tooltipValueGetter: (params) => params.value,
      },
      {
        headerName: fileDescriptions?.[0] || "1st Checkpoint",
        field: "first_file_checkpoint_value",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "1st Checkpoint",
      },
      {
        headerName: fileDescriptions?.[1] || "2nd Checkpoint",
        field: "second_file_checkpoint_value",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "2nd Checkpoint",
      },
      {
        headerName: "Override Results",
        field: "overrideResults",
        cellRenderer: OverrideResultsButtonRenderer,
        cellRendererParams: {
          disabled: disabledButtons,
          onClick: (e, data) => {
            console.log("useResultsPageColumnDefs.OverrideResultsButtonRenderer.onClick", data);
            setOverrideData(data);
          },
        },
        headerTooltip: "Override Results",
      },
      {
        headerName: "Notes",
        field: "review_notes",
        tooltipValueGetter: (params) => params.value,
        headerTooltip: "Notes",
      },
    ],
    [fileDescriptions]
  );
};

export default useResultsPageColumnDefs;
