import { useMutation } from "@tanstack/react-query";
import useAccessToken from "./useAccessToken";
import getEnvVars from "../utils/getEnvVars";
import mockData from "../services/mocks/complete-results";

const { REACT_APP_USE_MOCKED_BACKEND, REACT_APP_BACKEND_URL } = getEnvVars();

/**
 * Custom hook that provides mutation functionality for completing a comparison.
 * Uses React Query's useMutation to handle the state and lifecycle of server mutations.
 * Automatically includes the access token in the request.
 *
 * @returns {Object} A mutation object with the following properties:
 * @returns {Function} .mutate - Function to trigger the mutation
 * @returns {boolean} .isLoading - Whether the mutation is in progress
 * @returns {boolean} .isError - Whether the mutation encountered an error
 * @returns {Object} .error - Error object if the mutation failed
 * @returns {boolean} .isSuccess - Whether the mutation was successful
 * @returns {Object} .data - Data returned from the successful mutation
 */
const useComparisonCompleteMutator = () => {
  const accessTokenPromise = useAccessToken();

  return useMutation({
    mutationFn: async (comparison_id) => {
      if (!comparison_id) {
        throw new Error("comparison_id is required");
      }

      if (REACT_APP_USE_MOCKED_BACKEND === "true") {
        return mockData;
      }

      const accessToken = await accessTokenPromise;
      return fetch(`${REACT_APP_BACKEND_URL}api/complete-results?comparison_id=${comparison_id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => response.json());
    },
  });
};

export default useComparisonCompleteMutator;
