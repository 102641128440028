import { useQuery } from "@tanstack/react-query";
import getClientDataByJobId from "../services/get-job-data-by-jobId";
import useAccessToken from "./useAccessToken";

const useJobDetailsData = (jobId) => {
  const accessTokenPromise = useAccessToken();

  return useQuery({
    queryKey: ["jobDetailsData", jobId],
    queryFn: async () => {
      if (!jobId) {
        return null;
      }
      const accessToken = await accessTokenPromise;
      return getClientDataByJobId(jobId, accessToken);
    },
    enabled: !!jobId,
  });
};

export default useJobDetailsData;
