import { Button } from "@mui/material";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const ClearFiltersButton = ({ gridRef }) => {
  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  useEffect(() => {
    const checkFilters = () => {
      console.log("checkfilters");
      try {
        if (!gridRef?.current?.api?.getFilterModel) {
          setHasActiveFilters(false);
          return;
        }

        const filterModel = gridRef.current.api.getFilterModel() || {};
        setHasActiveFilters(Object.keys(filterModel).length > 0);
      } catch (error) {
        console.error("Error checking filters:", error);
        setHasActiveFilters(false);
      }
    };

    const api = gridRef?.current?.api;
    if (!api?.addEventListener) {
      console.error("Error trying to add listener to the grid, is it not rendered yet? gridRef", gridRef);
      return;
    } else {
      console.log("Adding listener to grid");
    }

    // Check initial state
    checkFilters();

    // Add listener for future changes
    api.addEventListener("filterChanged", checkFilters);
    return () => {
      api?.removeEventListener?.("filterChanged", checkFilters);
    };
  }, [gridRef]);

  const handleClearFilters = () => {
    try {
      if (!gridRef?.current?.api?.setFilterModel) {
        console.error("Grid API not available for clearing filters");
        return;
      }
      gridRef.current.api.setFilterModel(null);
    } catch (error) {
      console.error("Error clearing filters:", error);
    }
  };

  if (!hasActiveFilters) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      startIcon={<FilterAltOffIcon />}
      onClick={handleClearFilters}
      sx={{
        marginRight: "20px",
        color: "#2F94CD",
        borderColor: "#2F94CD",
        "&:hover": {
          borderColor: "#2F94CD",
          backgroundColor: "rgba(47, 148, 205, 0.04)",
        },
      }}
    >
      Clear Filters
    </Button>
  );
};

ClearFiltersButton.propTypes = {
  gridRef: PropTypes.shape({
    current: PropTypes.shape({
      api: PropTypes.shape({
        getFilterModel: PropTypes.func,
        setFilterModel: PropTypes.func,
        addEventListener: PropTypes.func,
        removeEventListener: PropTypes.func,
      }),
    }),
  }).isRequired,
};

export default ClearFiltersButton;
